import React, { useEffect, useRef } from "react"
import Typed from "typed.js"

const FEATURE_LIST = [
  "superpowers",
  "native versioning",
  "real-time collaboration",
  "parameterized execution",
  "scheduled runs",
  "cluster management",
]

export default function NewHeroSection() {
  const dynamicSpan = useRef(null)

  useEffect(() => {
    const spanId = "#typing-animation-features"
    // const dynamicTitleSpan = document.getElementById(spanId)
    new Typed(spanId, {
      loop: true,
      strings: FEATURE_LIST,
      showCursor: true,
      smartBackspace: true,
      typeSpeed: 100,
      backSpeed: 50,
    }).start()
  }, [])

  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div>
              {/* <img
                className="h-11 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=rose&shade=600"
                alt="Workflow"
              /> */}
              <img
                className="h-12 w-auto"
                src="/SupernoteLogo@2x.png"
                alt="Supenote"
              />
            </div>
            <div className="mt-10">
              <div>
                <a href="/" className="inline-flex space-x-4">
                  <span className="rounded bg-blue-50 px-2.5 py-1 text-xs font-semibold text-blue-600 tracking-wide uppercase">
                    Coming soon
                  </span>
                </a>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Python Notebook with
                  <br />
                  <span
                    ref={dynamicSpan}
                    id="typing-animation-features"
                    className="bg-gradient-to-tr bg-clip-text text-transparent from-blue-500 to-blue-600"
                  ></span>
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Supernote is the{" "}
                  <strong className="underline">
                    most powerful python notebook
                  </strong>{" "}
                  ever made.
                  <br />
                  <span className="inline-block mt-4">
                    Jupyter-compatible, with real-time collaboration, native
                    versioning, cluster management and more.
                  </span>
                  <br />
                  <span className="inline-block mt-4">
                    Coming soon, sign up for updates!
                  </span>
                </p>
              </div>
              <form
                action="https://formspree.io/f/mayawbgp"
                className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                method="post"
                target="_blank"
              >
                <div className="min-w-0 flex-1">
                  <label htmlFor="mce-EMAIL" className="sr-only">
                    Email address
                  </label>
                  <input
                    name="EMAIL"
                    type="email"
                    className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-700 focus:ring-blue-500"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <input
                    type="submit"
                    id="mc-embedded-subscribe"
                    value="Keep me posted!"
                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-gradient-to-tr from-blue-500 to-blue-600 text-base font-medium text-white shadow hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src="/dashboard.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
