import React from "react"
import { FooterSection } from "./components/sections/footer/FooterSection"
import NewHeroSection from "./components/sections/hero/NewHeroSection"

function App() {
  return (
    <div className="min-h-screen bg-white">
      <NewHeroSection />
      <FooterSection />
    </div>
    // <header className="bg-gray-800 w-screen h-screen flex items-center justify-center">
    //   <p className="bg-gradient-to-tr text-6xl font-black from-purple-700 to-pink-600 bg-clip-text text-transparent">
    //     Edit src/App.tsx and save to reload.
    //   </p>
    // </header>
  )
}

export default App
