import React from "react"

const CopyrightComponent = () => (
  <div className="mt-8 md:mt-0 md:order-1">
    <p className="text-center text-base text-gray-400">
      &copy; 2021 Supernote AI, Inc. All rights reserved.
    </p>
  </div>
)

export const FooterSection = () => (
  <footer className="bg-white">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <CopyrightComponent />
      {/* <FooterSocialLinkCollection /> */}
    </div>
  </footer>
)
